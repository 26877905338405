<template>
  <div id="root-layout" class="signup-done-page">
    <div class="masthead">
      Earn TCKL coins<br>
      with small tasks
    </div>

    <div class="ico-container">
      <div class="ico-container__div">
        <iframe src="https://embed.lottiefiles.com/animation/16841"></iframe>
      </div>
      <p class="ico-container__p">Please wait</p>
    </div>
  </div>
</template>

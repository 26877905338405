import {createRouter, createWebHashHistory} from "vue-router";

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("../views/TicklHome"),
    },
    {
      path: "/start",
      component: () => import("../views/StartPage.vue"),
    },
    {
      path: "/intro",
      component: () => import("../views/IntroPage.vue"),
    },
    {
      path: "/register",
      component: () => import("../views/Register.vue"),
    },
    {
      path: "/tickl",
      component: () => import("../views/TicklHome.vue"),
    },
    {
      path: "/loading",
      component: () => import("../views/PageLoading.vue"),
    },
    {
      path: "/terms",
      component: () => import("../views/Terms.vue"),
    },
    {
      path: "/redeem",
      component: () => import("../views/Redeem.vue"),
    },
    {
      path: "/redeem_detail",
      name: "redeemDetail",
      component: () => import("../views/RedeemDetail.vue"),
      props: true,
    },
    {
      path: "/redeem_confirm",
      name: "redeemConfirm",
      component: () => import("../views/RedeemConfirm.vue"),
      props: true,
    },
    {
      path: "/mission_detail",
      name: "missionDetail",
      component: () => import("../views/MissionDetail.vue"),
      props: true,
    }
  ],
});

export default router;

import { createApp } from 'vue'
import { createStore } from 'vuex'
import { getAnalytics } from "firebase/analytics";

import App from './App.vue'
import router from './router'
import {firebaseApp} from "@/firebase/firebase_app";
import AppConstant from "@/AppConstant";

getAnalytics(firebaseApp)
const app = createApp(App);
app.config.globalProperties.$host = "http://0.0.0.0/"

const store = createStore({
    state: {
        count: 1,
        user: {},
        googleAuth: false,
        apiKey: AppConstant.DEFAULT_API_KEY
    },
    mutations: {
        increment (state) {
            // mutate state
            state.count++
        },
        assignUserData(state, userData){
            Object.assign(state.user,userData);
        },
        changeGoogleAuthStatus(state, authStatus){
            state.googleAuth = authStatus;
        },
        refreshApiKey(state, key){
            state.apiKey = key;
        }
    }
})

app.use(router);
app.use(store);
app.mount('#app');


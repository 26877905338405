<template>
  <page-loading v-if="isLoading"></page-loading>
  <router-view v-else />
</template>

<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T4PMGCZ');</script>
<!-- End Google Tag Manager -->

<script setup>
import {onMounted, ref, onBeforeMount} from 'vue' // used for conditional rendering
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import axios from 'axios';
import AppConstant from "@/AppConstant";
import PageLoading from "@/views/PageLoading";

  const store = useStore();
  const router = useRouter()
  // const isLoggedIn = ref(true)
  const isLoggedIn = ref(false)
  const isLoading = ref(true)
  const auth = getAuth();
  const bgColor = ref("#f0f0f0")
  const primaryColor = ref("#ff7517")

  onBeforeMount(()=>{
    setTimeout(async () => {
      if(isLoading.value && store.state.googleAuth==false && !window.location.href.includes("/terms")){
        await router.push("/start");
        isLoading.value=false;
      }
    }, 5000);
  })

  onMounted(async ()=>{
    let urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('key')){
      store.commit('refreshApiKey',urlParams.get('key'))
    }
    const publisherInfo = await getPublisherInfo();
    if(publisherInfo.data.bg_color!="" && publisherInfo.data.primary_color!=""){
      bgColor.value = publisherInfo.data.bg_color;
      primaryColor.value = publisherInfo.data.primary_color;
      changeColor()
    }
  })
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userFromBack = await checkSignUp(user);
      isLoggedIn.value = true // if we have a user
      await store.commit('assignUserData',userFromBack.data)
      await store.commit('changeGoogleAuthStatus', true)
      await router.push("/tickl");
      isLoading.value = false;
    } else {
      isLoggedIn.value = false // if we do not
      store.commit('changeGoogleAuthStatus', false)
    }
    // isLoading.value = false;
  }, error => {console.log("auth error"+error)}, complete=>{console.log("completed"+complete)});
  // const clickSignOut = () => {
  //   signOut(auth)
  //   router.push('/')
  // }

  async function checkSignUp(user){
    const result = await axios.post(AppConstant.HOST+"/users",{
      social_id: user.email,
      social_app: "google",
      email: user.email,
      display_name: user.displayName,
      photoURL: user.photoURL,
      google_auth_id: user.uid,
      key: store.state.apiKey,
      uid: user.uid
    })
    return result;
  }

  async function getPublisherInfo(){
    const result = await axios.post(AppConstant.HOST+"/publisher_info",{
      key: store.state.apiKey,
    })
    return result;
  }

  function changeColor(){
    const css = `
      body {
        background: ${bgColor.value};
      }
      .mission-page .myinfo dl>*{
        color:${primaryColor.value};
      }

      .masthead{
        color:${primaryColor.value};
      }

      .mission-detail-reward{
        color:${primaryColor.value};
      }

      .btn{
        background:${primaryColor.value};
      }
    `;
    const styleTag = document.createElement('style');
    styleTag.appendChild(document.createTextNode(css));
    document.head.appendChild(styleTag);
  }

  // function changeLoadingStatus(status){
  //   console.log("from child "+status)
  //   isLoading.value = status;
  // }
</script>

<style>
  @import '@/assets/css/commons.css?v=2';
  #app{
    margin-top:26px;
  }
  .mission-page .myinfo dl>*{
    color:#ff7517;
  }

  .masthead{
    color:#ff7517;
  }

  .btn{
    background:#ff7517;
  }

  body{
    background:#f0f0f0;
  }
</style>

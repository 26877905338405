<script>
const HOST = process.env.VUE_APP_HOST
const DEFAULT_API_KEY = process.env.NODE_ENV=="development"?"TlVknaDLonZ/n/cmOaPO20wQ2G+skqTdG13bAwfMThyBUZBMj9MaL047h1AsgPBCaFpd1Vou3W3B48ZWn6zoXNQ687KZ+TczkYNfXwDAzSJLfMAGqM+BegYruq3mga/sgAXSc38Lujn0icCI0IPca3UgN2ka4O59eNt75rBfEN0="
    :"";

export default {
  HOST: HOST,
  DEFAULT_API_KEY: DEFAULT_API_KEY
}
</script>

<style scoped>

</style>
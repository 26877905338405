import { initializeApp } from "firebase/app";
// Import the functions you need from the SDKs you neednpm install -g firebase-tools
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCWA_Uzi1WHOOEcL0Ike-USmcfPOxePYCg",
    authDomain: "x2e-project.firebaseapp.com",
    projectId: "x2e-project",
    storageBucket: "x2e-project.appspot.com",
    messagingSenderId: "673456284165",
    appId: "1:673456284165:web:7e8e8c0b548ef79caff26c",
    measurementId: "G-37S17KDQ76"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export {app as firebaseApp}